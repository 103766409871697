.apexcharts-title-text {
  font-weight: $font-weight-bold !important;
}

.apexcharts-tooltip-title {
  font-weight: $font-weight-bold !important;
  padding-bottom: .25rem !important;
}

.apexcharts-gridline,
.apexcharts-ycrosshairs,
.apexcharts-xaxis-tick {
  stroke: $border-color !important;
}

.apexcharts-tooltip {
  line-height: 1;
  color: #fff;
  background: $dark !important;
  border: 0 !important;
  border-radius: 3px !important;
}

.apexcharts-tooltip-title {
  margin: 0 !important;
  background: transparent !important;
  border: 0 !important;
}

.apexcharts-tooltip-series-group {
  padding: 0 8px !important;
}

.apexcharts-tooltip-marker {
  width: .5rem;
  height: .5rem;
}

.apexcharts-gridline,
.apexcharts-ycrosshairs,
.apexcharts-xaxis-tick {
  stroke: $gray-300;
}

.apexcharts-yaxis-label,
.apexcharts-xaxis-label {
  fill: $text-muted;
}
