.nav-tabs {
  .icon {
    color: $text-muted;
  }

  .nav-link {
    padding: $card-title-spacer-y $card-spacer-x;
    line-height: 20px;
    color: $text-muted;
    cursor: pointer;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    transition: .3s color, .3s border-color;

    &:hover {
      color: $body-color;
      border-color: transparent;
    }

    &.disabled {
      color: rgba($text-muted, .2);
    }

    &.active {
      color: $body-color;
      background: inherit;
      border-color: transparent;
      border-bottom-color: $primary;
    }
  }
}

.nav-tabs-alt {
  .nav-link {
    font-size: 13px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

.nav-icon {
  line-height: 1;
  margin-right: .25rem;

  .icon {
    display: block;
    stroke-width: 1.5;
  }
}

.nav-link {
  display: flex;
  transition: .3s color;
  align-items: center;

  .icon {
    display: inline-block;
    font-size: 1rem;
  }
}

.nav-link-icon {
  width: 1.25rem;
  height: 1rem;
  font-size: 1rem;

  .icon {
    display: block;
  }
}